// 基本单位
@hd: 1px;

// 全局/品牌色
@brand-color: #3f45ff;

// Color Palette
@black: #000;
@white: #fff;
@gray-1: #f7f8fa;
@gray-2: #f2f3f5;
@gray-3: #ebedf0;
@gray-4: #dcdee0;
@gray-5: #c8c9cc;
@gray-6: #969799;
@gray-7: #646566;
@gray-8: #323233;
@blue: @brand-color;
@red: #f44336;
@orange: #ff976a;
@orange-dark: #ff590d;
@orange-light: #fffbe8;
@green: #00c853;

// The prefix to use on all css classes from react-vant.
@rv-prefix: rv;

// Grid system
@grid-columns: 24;

// Gradient Colors
@gradient-red: linear-gradient(to right, #ff6034, #ee0a24);
@gradient-orange: linear-gradient(to right, #ffd01e, #ff8917);

// Component Colors
@primary-color: @blue;
@success-color: @green;
@danger-color: @red;
@warning-color: @orange;
@text-color: @gray-8;
@active-color: @gray-2;
@active-opacity: 0.7;
@disabled-opacity: 0.5;
@background-color: @gray-1;
@background-color-light: #fafafa;
@text-link-color: #576b95;

// Padding
@padding-base: 4 * @hd;
@padding-xs: @padding-base * 2;
@padding-sm: @padding-base * 3;
@padding-md: @padding-base * 4;
@padding-lg: @padding-base * 6;
@padding-xl: @padding-base * 8;

// Font
@font-size-xs: 10 * @hd;
@font-size-sm: 12 * @hd;
@font-size-md: 14 * @hd;
@font-size-lg: 16 * @hd;
@font-size-xl: 18 * @hd;
@font-size-xxl: 20 * @hd;
@font-size-xxxl: 24 * @hd;
@font-weight-bold: 500;
@line-height-xs: 14 * @hd;
@line-height-sm: 18 * @hd;
@line-height-md: 20 * @hd;
@line-height-lg: 22 * @hd;
@line-height-xl: 24 * @hd;
@line-height-xxl: 28 * @hd;
@line-height-xxxl: 32 * @hd;
@base-font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial,
  Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
@price-integer-font-family: Avenir-Heavy, PingFang SC, Helvetica Neue, Arial, sans-serif;

// Animation
@animation-duration-base: 0.3s;
@animation-duration-fast: 0.2s;
@animation-timing-function-enter: ease-out;
@animation-timing-function-leave: ease-in;

// Border
@border-color: @gray-3;
@border-width-base: 1 * @hd;
@border-radius-sm: 2 * @hd;
@border-radius-md: 4 * @hd;
@border-radius-lg: 8 * @hd;
@border-radius-max: 999 * @hd;
