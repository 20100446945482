.rv-sku {
  /* sku row */
}
.rv-sku__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow-y: visible;
  font-size: 14px;
}
.rv-sku__body {
  flex: 1 1 auto;
  min-height: 44px;
}
.rv-sku__header {
  display: flex;
  flex-shrink: 0;
  margin: 0 16px;
}
.rv-sku__header__img-wrap {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin: 12px 12px 12px 0;
  overflow: hidden;
  border-radius: 4px;
}
.rv-sku__header__goods-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 12px 20px 12px 0;
}
.rv-sku__header-item {
  margin-top: 8px;
  color: #969799;
  font-size: 12px;
  line-height: 16px;
}
.rv-sku__price-symbol {
  font-size: 16px;
  vertical-align: bottom;
}
.rv-sku__price-num {
  font-weight: 500;
  font-size: 22px;
  vertical-align: bottom;
  word-wrap: break-word;
}
.rv-sku__goods-price {
  margin-left: -2px;
  color: var(--sku-theme-color, #f44336);
}
.rv-sku__price-tag {
  position: relative;
  display: inline-block;
  margin-left: 8px;
  padding: 0 5px;
  overflow: hidden;
  color: var(--sku-theme-color, #f44336);
  font-size: 12px;
  line-height: 16px;
  border-radius: 8px;
}
.rv-sku__price-tag::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: currentColor;
  opacity: 0.1;
  content: '';
}
.rv-sku__group-container {
  padding-top: 12px;
}
.rv-sku__group-container--hide-soldout .rv-sku-row__item--disabled {
  display: none;
}
.rv-sku-row {
  margin: 0 16px 12px;
}
.rv-sku-row:last-child {
  margin-bottom: 0;
}
.rv-sku-row__item,
.rv-sku-row__image-item {
  position: relative;
  overflow: hidden;
  color: #323233;
  border-radius: 4px;
  cursor: pointer;
}
.rv-sku-row__item::before,
.rv-sku-row__image-item::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7f8fa;
  content: '';
}
.rv-sku-row__item--active,
.rv-sku-row__image-item--active {
  color: var(--sku-theme-color, #f44336);
}
.rv-sku-row__item--active::before,
.rv-sku-row__image-item--active::before {
  background: currentColor;
  opacity: 0.1;
}
.rv-sku-row__item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  margin: 0 12px 12px 0;
  font-size: 13px;
  line-height: 16px;
  vertical-align: middle;
}
.rv-sku-row__item-img {
  z-index: 1;
  width: 24px;
  height: 24px;
  margin: 4px 0 4px 4px;
  object-fit: cover;
  border-radius: 2px;
}
.rv-sku-row__item-name {
  z-index: 1;
  padding: 8px;
}
.rv-sku-row__item--disabled {
  color: #c8c9cc;
  background: #f2f3f5;
  cursor: not-allowed;
}
.rv-sku-row__item--disabled .rv-sku-row__item-img {
  opacity: 0.3;
}
.rv-sku-row__image {
  margin-right: 0;
}
.rv-sku-row__image-item {
  display: flex;
  flex-direction: column;
  width: 110px;
  margin: 0 4px 4px 0;
  border: 1px solid transparent;
}
.rv-sku-row__image-item:last-child {
  margin-right: 0;
}
.rv-sku-row__image-item-img {
  width: 100%;
  height: 110px;
}
.rv-sku-row__image-item-img-icon {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  width: 18px;
  height: 18px;
  color: #fff;
  line-height: 18px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 4px;
}
.rv-sku-row__image-item-name {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 4px;
  font-size: 12px;
  line-height: 16px;
}
.rv-sku-row__image-item-name span {
  word-wrap: break-word;
}
.rv-sku-row__image-item--active {
  border-color: currentColor;
}
.rv-sku-row__image-item--disabled {
  color: #c8c9cc;
  cursor: not-allowed;
}
.rv-sku-row__image-item--disabled::before {
  z-index: 2;
  background: #f2f3f5;
  opacity: 0.4;
}
.rv-sku-row__title {
  padding-bottom: 12px;
}
.rv-sku-row__title-multiple {
  color: #969799;
}
.rv-sku-row__scroller {
  margin: 0 -16px;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.rv-sku-row__scroller::-webkit-scrollbar {
  display: none;
}
.rv-sku-row__row {
  display: inline-flex;
  margin-bottom: 4px;
  padding: 0 16px;
}
.rv-sku-row__indicator {
  width: 40px;
  height: 4px;
  background: #ebedf0;
  border-radius: 2px;
}
.rv-sku-row__indicator-wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
}
.rv-sku-row__indicator-slider {
  width: 50%;
  height: 100%;
  background-color: #f44336;
  border-radius: 2px;
}
.rv-sku__stepper-stock {
  padding: 12px 16px;
  overflow: hidden;
  line-height: 30px;
}
.rv-sku__stepper {
  float: right;
  padding-left: 4px;
}
.rv-sku__stepper-title {
  float: left;
}
.rv-sku__stepper-quota {
  float: right;
  color: #f44336;
  font-size: 12px;
}
.rv-sku__stock {
  display: inline-block;
  margin-right: 8px;
  color: #969799;
  font-size: 12px;
}
.rv-sku__stock-num--highlight {
  color: #f44336;
}
.rv-sku__actions {
  flex-shrink: 0;
  padding: 0 16px;
}
.rv-sku__actions .rv-action-bar {
  position: relative;
}
.rv-sku__actions .rv-action-bar .rv-button {
  margin: 0;
}
